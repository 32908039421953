import React, { useEffect, useState } from "react";
import PrimaryBar from "../../components/PrimaryBar";
import SecondaryBar from "../../components/SecondaryBar";
import Footer from "../../components/Footer";
import "./BaselineForecastResults.css";
import { PropTypes } from "prop-types";
import { getData } from "../../app/data";
import getBaselineForecastResults from "./data";
import { Spinner } from "reactstrap";
import {
  Area,
  AreaChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { formatValue } from "../../app/utils";
import { Redirect } from "react-router-dom";
import ReactDataSheet from "react-datasheet";
import { forecastRecordFilters, getSelectedFilters } from "../../app/filters";
import { bindActionCreators } from "redux";
import {
  modifyFilterSelectionsAction,
  modifyFilterValuesAction,
} from "../../redux/actions/filters.action";
import { connect } from "react-redux";
import ContextBar from "../../components/V2/ContextBar/ContextBar";

/**
 * Forecast Results Component
 * @param {Object} props the properties for the component, namely the ID.
 * @return {JSX}
 */
const BaselineForecastResults = (props) => {
  const { filterSelections, filterValues } = props;
  const forecastVersionId = props.match.params.forecast_version_id;

  const [data, setData] = useState({
    donor: {
      chartData: [],
      tableData: [],
    },
    revenue: [],
  });
  const [isLoading, setLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [redirectNew, setRedirectNew] = useState(false);

  /**
   * Fetches Data from required data endpoints and returns them to the screen
   * through a hook.
   */
  useEffect(() => {
    setLoading(true);
    const filters = getSelectedFilters(
      filterSelections,
      filterValues,
      forecastRecordFilters
    );
    getBaselineForecastResults(filters, forecastVersionId).then((response) => {
      setData(response);
      // Set the loading animation state to false
      setLoading(false);
    });
  }, [filterSelections, filterValues, forecastVersionId]);

  // Loading Div for the download button
  let downloadLoadingDiv = "";
  if (downloadLoading) {
    downloadLoadingDiv = <Spinner color="primary" />;
  }

  /**
   * The download function for when the download button is pressed.
   * @return {Promise<void>}
   */
  async function getCSV() {
    // Set loading to true
    setDownloadLoading(true);
    // change endpoint if endpoint in backend changes
    const csv = await getData(
      "forecast/export?forecast_version_id[]=" + forecastVersionId
    );
    if (csv !== null) {
      window.open(csv.url, "_self");
    }
    setDownloadLoading(false);
  }

  /**
   * Redirect the user to the new forecast page.
   */
  function handleNewForecast() {
    setRedirectNew(true);
  }

  if (redirectNew) {
    return <Redirect push to={"/forecast/new"} />;
  }

  return (
    <div className="wholepage">
      <header>
        <PrimaryBar />
      </header>
      <div className="main-content">
        <SecondaryBar />
        <div className="content_section">
          <ContextBar
            title={"Baseline Forecast Results"}
            parentLoading={isLoading}
            footer={" "}
            context={{ "forecast-version-id": [forecastVersionId] }}
            shortcutFilters={forecastRecordFilters}
            sidebarFilters={forecastRecordFilters}
          />
          <div className="page-content">
            <div className="baseline-forecast-container">
              <div className="baseline-forecast-inner">
                <div className="baseline-forecast-row">
                  <div
                    className="baseline-forecast-column first"
                    data-cy="baseline-forecast-donors"
                  >
                    <h3 className="baseline-forecast-title">Forecast Donors</h3>
                    <div
                      className="baseline-chart-container"
                      data-cy="baseline-forecast-donors-chart"
                    >
                      <ResponsiveContainer
                        width="100%"
                        height="100%"
                        minHeight={250}
                        maxHeight={250}
                      >
                        <AreaChart
                          data={data.donor.chartData}
                          margin={{
                            top: 30,
                            bottom: 30,
                            left: 35,
                            right: 35,
                          }}
                        >
                          <XAxis
                            dataKey="Reporting Date"
                            label={{
                              value: "Reporting Month",
                              position: "insideBottom",
                              offset: -10,
                            }}
                          />
                          <YAxis
                            tickFormatter={(v) => formatValue(v, "###,##0")}
                            label={{
                              value: "Donors",
                              angle: -90,
                              offset: -10,
                              position: "insideLeft",
                            }}
                          />
                          <Tooltip
                            formatter={(v) => formatValue(v, "###,##0")}
                          />
                          <Area
                            key="Donors"
                            type="monotone"
                            dataKey="Donors"
                            stroke="#8884d8"
                            fill="#8884d8"
                          />
                        </AreaChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                  <div
                    className="baseline-forecast-column"
                    data-cy="baseline-forecast-revenue"
                  >
                    <h3 className="baseline-forecast-title">
                      Revenue by Month
                    </h3>
                    <div
                      className="baseline-chart-container"
                      data-cy="baseline-forecast-revenue-chart"
                    >
                      <ResponsiveContainer
                        width="100%"
                        height="100%"
                        minHeight={250}
                        maxHeight={250}
                      >
                        <AreaChart
                          data={data.revenue}
                          margin={{
                            top: 30,
                            bottom: 30,
                            left: 35,
                            right: 35,
                          }}
                        >
                          <XAxis
                            dataKey="Reporting Date"
                            label={{
                              value: "Reporting Month",
                              position: "insideBottom",
                              offset: -10,
                            }}
                          />
                          <YAxis
                            tickFormatter={(v) => formatValue(v, "$###,##0")}
                            label={{
                              value: "Revenue $",
                              angle: -90,
                              offset: -10,
                              position: "insideLeft",
                            }}
                          />
                          <Tooltip
                            formatter={(v) => formatValue(v, "$###,##0")}
                          />
                          <Area
                            key="Revenue"
                            type="monotone"
                            dataKey="Revenue"
                            stroke="#8884d8"
                            fill="#8884d8"
                          />
                        </AreaChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                </div>
                <h3 className="baseline-forecast-title">
                  Remaining Donors by Cohort & Future Months
                </h3>
                <div
                  className="baseline-forecast-table"
                  data-cy="baseline-forecast-remaining-donors"
                >
                  <ReactDataSheet
                    data={data.donor.tableData}
                    valueRenderer={(cell) => cell.value}
                  />
                </div>
                <div className="download_btns">
                  <button
                    className="self_btn darkblue_btn"
                    data-cy="baseline-forecast-export-button"
                    onClick={getCSV}
                  >
                    <i className="fas fa-download" />
                    Export data to csv
                  </button>
                  {downloadLoadingDiv}
                  <button
                    className="self_btn lblue_btn"
                    data-cy="baseline-forecast-new-forecast-button"
                    onClick={handleNewForecast}
                  >
                    Run a new forecast
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer>
        <Footer />
      </footer>
    </div>
  );
};
const mapStateToProps = ({ filterSelections, filterValues }) => {
  return {
    filterSelections,
    filterValues,
  };
};

const mapActionsToProps = (dispatch) => {
  return bindActionCreators(
    {
      modifyFilterSelectionsAction: modifyFilterSelectionsAction,
      modifyFilterValuesAction: modifyFilterValuesAction,
    },
    dispatch
  );
};

BaselineForecastResults.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  filterValues: PropTypes.object,
  filterSelections: PropTypes.object,
  match: PropTypes.object,
  params: PropTypes.object,
  forecast_version_id: PropTypes.string,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(BaselineForecastResults);
