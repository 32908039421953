import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import {
  Form,
  FormGroup,
  Input,
  Label,
  Card,
  Spinner,
  CardBody,
  Button,
  Row,
  Col,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  modifyFilterSelectionsAction,
  modifyFilterValuesAction,
} from '../../redux/actions/filters.action';
import {
  INITIAL_FILTER_SELECTION_STATE,
  INITIAL_FILTER_VALUE_STATE,
} from '../../redux/reducers/filters.reducer';

import { login, setSessionInformation, logout } from '../../app/auth';

import './Login.css';
import { logUsage } from '../../app/utils';
import CookieConsentBar from '../../components/CookieConsentBar';

/**
 * The login screen view, presented without any branding, nav or footers to
 * allow the user to login.
 */
class Login extends Component {
  /**
   * Default constructor for the component
   * @param {Object} props The properties passed to the component
   */
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      errorMsg: '',
      registerMsg: props.registerMsg,
      isLoading: false,
    };
  }

  componentDidMount() {
    // Reset redux state
    const { modifyFilterSelections, modifyFilterValues } = this.props;
    modifyFilterSelections(INITIAL_FILTER_SELECTION_STATE);
    modifyFilterValues(INITIAL_FILTER_VALUE_STATE);
  }

  /**
   * Function executed when the password field is modified
   * @param {Object} e The event triggering the function.
   */
  handlePasswordChange(e) {
    const value = e.target.value;
    this.setState({
      password: value,
    });
  }
  /**
   * Function executed when the username field is modified
   * @param {Object} e The event triggering the function.
   */
  handleUsernameChange(e) {
    const value = e.target.value;
    this.setState({
      username: value,
    });
  }
  /**
   * Function execute when the screen' submit button is pressed.
   * @param {Object} e The event triggering the function.
   */
  async handleSubmit(e) {
    e.preventDefault();
    // reset error message
    this.setState({
      errorMsg: '',
    });
    // get client IP address
    this.setState({
      isLoading: true,
    });

    login(this.state.username, this.state.password, null).then((res) => {
      if (Object.prototype.hasOwnProperty.call(res, 'message')) {
        this.setState({
          isLoading: false,
          errorMsg: res['message'],
        });
      } else {
        setSessionInformation(res.token, res.logoURL);
        this.setState({
          loginSuccess: true,
        });
        logUsage('Login', 'Authentication', 'Login');
      }
    });
  }
  /**
   * The render function for the component, returns the login screen.
   * @return {JSX}
   */
  render() {
    let { registerMsg } = '';
    const { errorMsg, loginSuccess, isLoading } = this.state;
    // If the registration message has been passed, set it.
    if (typeof this.props.location.state !== 'undefined' && this.props.location.state !== null) {
      registerMsg = this.props.location.state.registerMsg;
    }
    // If an error message has been generated, null out the registration message
    if (errorMsg !== '') {
      registerMsg = '';
    }
    // The Spinner icon if the isLoading is true
    let loadingSpinner;
    if (isLoading) {
      loadingSpinner = <Spinner color="primary" />;
    }
    // If they have successfully logged in, redirect to the home page
    if (loginSuccess) {
      const params = new URLSearchParams(this.props.location.search.slice(1));
      if (params.has('redirect')) {
        return <Redirect push to={params.get('redirect')} />;
      }
      return <Redirect push to={'/home'} />;
    } else {
      logout();
    }
    return (
      <div className="container">
        <div className="login-parent">
          <Card className="login-card">
            <CardBody className={'login-card-body'}>
              <h5>Login to your account</h5>
              <br />
              <Form action="#" onSubmit={(e) => this.handleSubmit(e)}>
                <FormGroup>
                  <Label for="username" className={'black-label'}>
                    Username
                  </Label>
                  <Input
                    type="text"
                    name="username"
                    id="username"
                    onChange={this.handleUsernameChange.bind(this)}
                    autoComplete="on"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="password" className={'black-label'}>
                    Password
                  </Label>
                  <Input
                    type="password"
                    name="password"
                    id="password"
                    onChange={this.handlePasswordChange.bind(this)}
                    autoComplete="on"
                  />
                </FormGroup>
                <FormGroup>
                  {typeof this.state.errorMsg !== 'undefined' && (
                    <div>
                      <p className="error-msg">{this.state.errorMsg}</p>
                      <br />
                    </div>
                  )}
                  {typeof registerMsg !== 'undefined' && (
                    <p className="register-msg">{registerMsg}</p>
                  )}
                  <Row>
                    <Col>
                      <Button data-cy="login-button" type="submit" innerRef="Login">
                        Login
                      </Button>
                    </Col>
                    <Col>{loadingSpinner}</Col>
                  </Row>
                  <Row>
                    <Col>
                      <Link to="/register">Register</Link>
                    </Col>
                    <Col>
                      <Link to="/forgot">Forgot My Password</Link>
                    </Col>
                  </Row>
                </FormGroup>
              </Form>
            </CardBody>
          </Card>
        </div>
        <CookieConsentBar />
      </div>
    );
  }
}

const mapStateToProps = ({ filterSelections, filterValues }) => {
  return {
    filterSelections,
    filterValues,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    modifyFilterSelections: (data) => dispatch(modifyFilterSelectionsAction(data)),
    modifyFilterValues: (data) => dispatch(modifyFilterValuesAction(data)),
  };
}

Login.propTypes = {
  location: PropTypes.object,
  registerMsg: PropTypes.string,
  modifyFilterSelections: PropTypes.func,
  modifyFilterValues: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
