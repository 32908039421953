import React from "react";
import "./Logout.css";
import { Redirect } from "react-router-dom";

// eslint-disable-next-line no-unused-vars
function Logout(props) {
  // Remove the authorisation token.
  localStorage.clear();

  // Redirect back to the login screen.
  return <Redirect push to={"/login"} />;
}

export default Logout;
