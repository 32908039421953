import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from 'reactstrap';
import { register } from '../../app/auth';
import { validateEmail } from '../../app/utils';
import PasswordStrengthMeter from '../../components/PasswordStrengthMeter';

import './Register.css';
import CookieConsentBar from '../../components/CookieConsentBar';

/**
 * The login screen view, presented without any branding, nav or footers to
 * allow the user to login.
 */
class Register extends Component {
  /**
   * Default constructor for the component
   * @param {Object} props The properties passed to the component
   */
  constructor(props) {
    super(props);
    this.state = {
      organisation: '',
      clientSecret: '',
      username: '',
      password: '',
      confirmPassword: '',
      errorMsg: '',
      responseCode: 0,
      clientIP: [],
      isLoading: false,
      passwordStrength: 0,
    };
  }
  /**
   * Function executed when the organisation (client) field is modified
   * @param {Object} e The event triggering the function.
   */
  handleOrganisationChange(e) {
    const value = e.target.value;
    this.setState({
      organisation: value,
    });
  }
  /**
   * Function executed when the organisation secret field is modified
   * @param {Object} e The event triggering the function.
   */
  handleOrganisationSecretChange(e) {
    const value = e.target.value;
    this.setState({
      clientSecret: value,
    });
  }
  /**
   * Function executed when the password field is modified
   * @param {Object} e The event triggering the function.
   */
  handlePasswordChange(e) {
    const value = e.target.value;
    this.setState({
      password: value,
    });
  }
  /**
   * Function executed when the confirmation password field is modified
   * @param {Object} e The event triggering the function.
   */
  handleConfirmPasswordChange(e) {
    const value = e.target.value;
    this.setState({
      confirmPassword: value,
    });
  }
  /**
   * Function executed when the username field is modified
   * @param {Object} e The event triggering the function.
   */
  handleUsernameChange(e) {
    const value = e.target.value;
    this.setState({
      username: value,
    });
  }
  /**
   * Function executed when the organisation (client) field is modified
   * @param {Object} selectedValue The event triggering the function.
   */
  selectedValueHandler(selectedValue) {
    if (selectedValue !== this.state.passwordStrength) {
      this.setState({
        passwordStrength: selectedValue,
      });
    }
  }
  /**
   * Function executed when the registration is submitted.
   * @param {Object} e The event triggering the submission.
   */
  async handleSubmit(e) {
    e.preventDefault();
    // reset error message
    this.setState({
      errorMsg: '',
    });
    // Check the passwords are the same
    if (this.state.password !== this.state.confirmPassword) {
      this.setState({
        errorMsg: 'Passwords do not match',
      });
      return;
    }
    // Check the email format
    if (!validateEmail(this.state.username)) {
      this.setState({
        errorMsg: 'Invalid email format',
      });
      return;
    }
    // get client IP address
    this.setState({
      isLoading: true,
    });

    // login process
    const res = await register(
      this.state.organisation,
      this.state.clientSecret,
      this.state.username,
      this.state.password,
    );
    if (res.status_code !== 200) {
      this.setState({
        errorMsg: res.message,
        responseCode: res.status_code,
      });
    } else {
      this.setState({
        registerSuccess: true,
      });
    }
  }
  /**
   * The render function for the component, returns the registration screen.
   * @return {JSX}
   */
  render() {
    const { password, registerSuccess, isLoading } = this.state;
    if (registerSuccess) {
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: {
              registerMsg: 'Successfully registered, please login.',
            },
          }}
        />
      );
    }
    // The Spinner icon if the isLoading is true
    let loadingSpinner;
    if (isLoading) {
      loadingSpinner = <Spinner color="primary" />;
    }
    return (
      <div className="container">
        <div className="register-parent">
          <Card className="register-card">
            <CardBody className="register-title">
              <h5>Register a New Account</h5>
              <br />
              <Form action="#" onSubmit={(e) => this.handleSubmit(e)}>
                <FormGroup>
                  <Label for="client" className={'black-label'}>
                    Organisation
                  </Label>
                  <Input
                    type="text"
                    name="organisation"
                    id="client"
                    onChange={this.handleOrganisationChange.bind(this)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="client_id" className={'black-label'}>
                    Organisation Secret
                  </Label>
                  <Input
                    type="text"
                    name="organisation"
                    id="client_id"
                    onChange={this.handleOrganisationSecretChange.bind(this)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="username" className={'black-label'}>
                    Email
                  </Label>
                  <Input
                    type="text"
                    name="Email"
                    id="Email"
                    autoComplete="on"
                    onChange={this.handleUsernameChange.bind(this)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="password" className={'black-label'}>
                    Password (Must be good or better)
                  </Label>
                  <Input
                    type="password"
                    name="password"
                    id="password"
                    autoComplete="on"
                    onChange={this.handlePasswordChange.bind(this)}
                  />
                  <div className="meter" id="strengthMeter">
                    <PasswordStrengthMeter
                      className="password-strength-meter"
                      password={password}
                      selectedValueHandler={this.selectedValueHandler.bind(this)}
                    />
                  </div>
                  <Label for="confirm-password" className={'black-label'}>
                    Confirm Password
                  </Label>
                  <Input
                    type="password"
                    name="confirm-password"
                    id="confirm-password"
                    onChange={this.handleConfirmPasswordChange.bind(this)}
                  />
                </FormGroup>
                <FormGroup>
                  {this.state.errorMsg.length > 0 && (
                    <p className="error-msg">{this.state.errorMsg}</p>
                  )}
                </FormGroup>
                <FormGroup>
                  <Row>
                    <Col>
                      <Button type="submit" innerRef="Register">
                        Register
                      </Button>
                    </Col>
                    <Col>{loadingSpinner}</Col>
                  </Row>
                </FormGroup>
              </Form>
            </CardBody>
          </Card>
        </div>
        <CookieConsentBar />
      </div>
    );
  }
}

export default Register;
