import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import PrimaryBar from '../../components/PrimaryBar'
import SecondaryBar from '../../components/SecondaryBar'
import Footer from '../../components/Footer'
import './ForecastView.css'
import ForecastRow from '../../components/ForecastRow'
import getViewForecastData, { deleteForecastVersion } from './data'
import { Button } from 'reactstrap'
import {
    forecastViewFilters,
    getSelectedFilters,
} from '../../app/filters'
import * as queryString from 'query-string'
import { bindActionCreators } from 'redux'
import {
    modifyFilterSelectionsAction,
    modifyFilterValuesAction,
} from '../../redux/actions/filters.action'
import { connect } from 'react-redux'
import ContextBar from '../../components/V2/ContextBar/ContextBar'

/**
 * Return the Forecast View screen, which lists all forecasts previously created
 * for this client.
 * @return {JSX}
 */
const ForecastView = (props) => {
    const { filterSelections, filterValues } = props
    const [isLoading, setLoading] = useState(false)
    const [data, setData] = useState({ existingForecasts: [] })
    const [deleteCount, setDeleteCount] = useState(0)
    const [collection, setCollection] = useState([])
    const [collectionUrl, setCollectionUrl] = useState('')

    useEffect(() => {
        setLoading(true)
        const filters = getSelectedFilters(filterSelections, filterValues,
          forecastViewFilters)
        getViewForecastData(filters).then((res) => {
            setData(res)
            setLoading(false)
        })
    }, [filterSelections, filterValues, deleteCount])

    const handleDeleteClick = (forecastVersionId) => {
        // login process
        setLoading(true)
        deleteForecastVersion(forecastVersionId).then((value) => {
              if (value.success) {
                  setDeleteCount(deleteCount + 1)
              }
          }, () => {
          },
        )
    }

    /**
     * Manage the state of the active collection item.
     * @param {string} forecastVersionId The forecast version id to add or pop
     * from the active elements list.
     */
    const toggleActive = (forecastVersionId) => {
        const rtn = []
        let foundPop = false
        collection.forEach(function (element) {
            if (element === forecastVersionId) {
                foundPop = true
            } else {
                rtn.push(element)
            }
        })
        if (!foundPop) {
            rtn.push(forecastVersionId)
        }
        rtn.sort()
        setCollection(rtn)
        const urlString = queryString.stringify({
            forecast_version_id: rtn,
        }, { arrayFormat: 'bracket' })
        setCollectionUrl(urlString)
    }

    const forecastDiv = data.existingForecasts.map((item, key) =>
      <ForecastRow
        data={item}
        key={key}
        toggleActive={toggleActive}
        handleDelete={handleDeleteClick}/>)

    let collectionDiv = <Button className={'view-collection'}>{'cat'}</Button>
    if (collection.length > 0) {
        collectionDiv =
          <Button outline color="secondary" size="sm"
                  href={'/forecast/results?' + collectionUrl}>
              View Collection
          </Button>
    }

    return (
      <div className='wholepage'>
          <header>
              <PrimaryBar/>
              <SecondaryBar/>
          </header>
          <div className='main-content'>
              <ContextBar
                title={'Previous Forecasts'}
                parentLoading={isLoading}
                footer={'View previously created forecasts or the predictive model of your organisation.'}
                shortcutFilters={['forecast-channel', 'forecast-supplier']}
                sidebarFilters={forecastViewFilters}/>
              <div className='content_section  no-pad-top'>
                  <div className='page-content no-pad-top'>
                      <div className={'view_container no-pad-top'}>
                          {collectionDiv}
                          {forecastDiv}
                      </div>
                  </div>
              </div>
          </div>
          <footer>
              <Footer/>
          </footer>
      </div>
    )
}

const mapStateToProps = ({ filterSelections, filterValues }) => {
    return {
        filterSelections,
        filterValues,
    }
}

const mapActionsToProps = (dispatch) => {
    return bindActionCreators({
        modifyFilterSelectionsAction: modifyFilterSelectionsAction,
        modifyFilterValuesAction: modifyFilterValuesAction,
    }, dispatch)
}

ForecastView.propTypes = {
    location: PropTypes.object,
    history: PropTypes.object,
    filterValues: PropTypes.object,
    filterSelections: PropTypes.object,
}

export default connect(mapStateToProps, mapActionsToProps)(ForecastView)
