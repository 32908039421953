import React from "react";
import PropTypes from "prop-types";
import { Redirect, withRouter } from "react-router-dom";

/**
 * The Home Route
 * @param {Object} props - the passed the props to the component
 * @return {JSX}
 */
function NotFound(props) {
  const { pathname } = props.location;
  // Navigate the user back to the correct URL if they
  // accidently navigate to one of the following URLs.
  if (pathname.startsWith("/forecast")) {
    return <Redirect to="/forecast/view" />;
  } else if (pathname.startsWith("/analytics")) {
    return <Redirect to="/analytics/kpi" />;
  }
  return <p>404 Not Found</p>;
}

NotFound.propTypes = {
  location: PropTypes.object.isRequired,
};

export default withRouter((props) => <NotFound {...props} />);
