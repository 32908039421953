/* eslint-disable max-len */
import React from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';

import Home from '../routes/Home';
import Login from '../routes/Login';
import Register from '../routes/Register';
import NotFound from '../routes/NotFound';
import AnalyticsCalendar from '../routes/AnalyticsCalendar';
import AnalyticsHallOfFame from '../routes/AnalyticsHallOfFame';
import AnalyticsDataQuality from '../routes/AnalyticsDataQuality';
import AnalyticsInstallment from '../routes/AnalyticsInstallment';
import AnalyticsKPI from '../routes/AnalyticsKPI';
import ForecastNew from '../routes/ForecastNew';
import ForecastResults from '../routes/ForecastResults';
import ForecastView from '../routes/ForecastView';
import Help from '../routes/Help';
import PasswordReset from '../routes/PasswordReset';
import { getRole, isAuthenticated } from './auth';
import BaselineForecastResults from '../routes/BaselineForecastResults';
import Members from '../routes/Members';
import Data from '../routes/Data';
import Invitation from '../routes/Invitation/Invitation';
import AnalyticsDeclines from '../routes/AnalyticsDeclines';
import AnalyticsBestDonorSegment from '../routes/AnalyticsBestDonorSegment';
import ErrorBoundary from '../components/ErrorBoundary';
import Logout from '../routes/Logout';
import ImportData from '../routes/ImportData/ImportData';
import PropTypes from 'prop-types';
import Policies from '../routes/Policies';
import UserVerification from '../routes/UserVerification';

/**
 * Contains all the routes required for the application
 * @return {JSX.Element} - JSX containing all the routes
 */
function Routes() {
  // eslint-disable-next-line react/prop-types
  const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) => {
        return isAuthenticated() === true ? (
          <ErrorBoundary>
            <Component {...props} />
          </ErrorBoundary>
        ) : (
          // eslint-disable-next-line react/prop-types
          <Redirect to={`/login?redirect=${props.location.pathname}${props.location.search}`} />
        );
      }}
    />
  );
  // eslint-disable-next-line react/prop-types
  const AdminRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() === true && getRole() === 'Administrator' ? (
          <Component {...props} />
        ) : (
          <Redirect to={isAuthenticated() ? '/home' : '/login'} />
        )
      }
    />
  );
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={Login} />
        <Route path="/login" exact component={Login} />
        <Route path="/logout" exact component={Logout} />
        <Route path="/register" exact component={Register} />
        <Route path="/invitation" exact component={Invitation} />
        <Route path="/forgot" exact component={PasswordReset} />
        <Route path="/policies" exact component={Policies} />
        <Route path="/verify" exact component={UserVerification} />
        <PrivateRoute path="/home" exact component={Home} />
        <PrivateRoute path="/analytics/calendar" exact component={AnalyticsCalendar} />
        <PrivateRoute path="/analytics/hall_of_fame" exact component={AnalyticsHallOfFame} />
        <PrivateRoute path="/analytics/data_quality" exact component={AnalyticsDataQuality} />
        <PrivateRoute path="/analytics/installment" exact component={AnalyticsInstallment} />
        <PrivateRoute path="/analytics/kpi" exact component={AnalyticsKPI} />
        <PrivateRoute path="/analytics/declines" exact component={AnalyticsDeclines} />
        <PrivateRoute
          path="/analytics/best_donor_segment"
          exact
          component={AnalyticsBestDonorSegment}
        />
        <PrivateRoute path="/forecast/new" exact component={ForecastNew} />
        <PrivateRoute path="/forecast/new/:forecast_version_id" component={ForecastNew} />
        <PrivateRoute
          exact
          path="/forecast/results"
          component={ForecastResults}
          providerUrl={false}
        />
        <PrivateRoute
          exact
          path="/forecast/results/baseline/:forecast_version_id"
          component={BaselineForecastResults}
          providerUrl={false}
        />
        <PrivateRoute path="/forecast/view" exact component={ForecastView} />
        <PrivateRoute path="/help" exact component={Help} />
        <AdminRoute path="/settings/members" exact component={Members} />
        <AdminRoute path="/settings/import" exact component={ImportData} />
        <AdminRoute path="/settings/data" exact component={Data} />
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
}

Routes.propTypes = {
  location: PropTypes.object,
};

export default Routes;
