import React, { useState } from "react";
import PropTypes from "prop-types";
import PrimaryBar from "../PrimaryBar";
import { Button } from "reactstrap";
import "./ErrorBoundary.css";
import { Redirect, withRouter } from "react-router-dom";
import { getUserId, isAuthenticated } from "../../app/auth";
import { logError } from "./data";

const currentDate = () => {
  const d = new Date();
  return d.toUTCString();
};

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null,
      user_id: getUserId(),
    };
    const { history } = this.props;

    // eslint-disable-next-line no-unused-vars
    history.listen((location, action) => {
      if (this.state.errorInfo) {
        this.setState({
          error: null,
          errorInfo: null,
        });
      }
    });
  }

  componentDidMount() {
    window.addEventListener("unhandledrejection", (event) => {
      event.preventDefault();
      this.setState({
        error: event.reason,
        errorInfo: event.reason,
      });
      this.storeError();
    });
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    this.storeError();

    // You can also log error messages to an error reporting service here
    // logErrorToMyService(error,errorInfo);
  }

  storeError() {
    const payload = {
      user_id: getUserId(),
      log_date: currentDate(),
      event_type: "App Crash",
      event_description:
        this.state.errorInfo +
        " " +
        this.props.location.pathname +
        " " +
        this.props.location.search,
    };
    logError("log", payload);
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <div>
          <ErrorInterface />
        </div>
      );
    }
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.any,
  history: PropTypes.object,
  location: PropTypes.object,
};
export default withRouter(ErrorBoundary);

function ErrorInterface() {
  const [redirectRoute, setRedirectRoute] = useState(false);

  if (redirectRoute) {
    return <Redirect to={isAuthenticated() ? "/home" : "/login"} />;
  }

  return (
    <div>
      <div className="wholepage">
        <header>
          <PrimaryBar selected="Home" />
        </header>
        <div className="errorBoundary main-content">
          <div className="vi_section error">
            <h2>An Error has occurred</h2>
            <br />
            <h5>
              The error has been logged and reported. We apologise for the
              inconvenience this may have caused.
            </h5>
            <div className="vibtn_wrap">
              <br />
              <Button
                className="self_btn vi_btn"
                onClick={() => setRedirectRoute(true)}
              >
                Go back to Home page
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
