import React from 'react';

import Routes from './routes';

/**
 * Handles the rendering for the main application
 * @return {JSX}
 */
function App() {
  return (
    <Routes />
  );
}

export default App;
